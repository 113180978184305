﻿
<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{ card_header_props.header.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{
            card_header_props.header.headLine
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            card_header_props.header.subTitle
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="
          card_header_props.helper.page_helper =
            !card_header_props.helper.page_helper
        "
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-btn @click="startTour" icon dark>
        <v-icon>help</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-title>{{ $t("_edit_profile.Profile") }}</v-card-title>
          </v-col>

          <v-card class="d-flex justify-center align-center col-md-4" flat tile>
            <image-input v-model="avatar" class="v-step-0">
              <div slot="activator">
                <v-avatar
                  size="150px"
                  v-ripple
                  v-if="!avatar"
                  class="grey lighten-3 mb-3"
                >
                  <span>Click to add avatar</span>
                </v-avatar>
                <v-avatar size="150px" v-ripple v-else class="mb-3">
                  <img :src="avatar.imageURL" alt="avatar" />
                </v-avatar>
              </div>
            </image-input>
          </v-card>

          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  v-model="firstName"
                  :counter="50"
                  :rules="validations.nameRules"
                  :label="this.$t('_edit_profile.First_Name')"
                  required
                  disabled
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  v-model="lastName"
                  :counter="50"
                  :rules="validations.nameRules"
                  :label="this.$t('_edit_profile.Last_Name')"
                  required
                  disabled
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  v-model="username"
                  :label="this.$t('_edit_profile.User_Name')"
                  disabled
                  class="v-step-1"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  v-model="email"
                  :rules="validations.emailRules"
                  :label="this.$t('_edit_profile.EMail')"
                  required
                  disabled
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-card class="d-flex flex-row-reverse" flat tile>
          <v-btn :disabled="!valid" color="success" class="ml-1 v-step-2" @click="save">{{$t("_edit_profile.Save")}}</v-btn>

           <v-btn color="error" @click="reset">{{$t("_edit_profile.Clear")}}</v-btn>
         </v-card>  -->
      </v-container>
    </v-form>
    <v-tour name="userProfile" :steps="steps"></v-tour>
  </v-card>
</template>

<script>
import ImageInput from "../../../views/components/extra-components/avatar-upload/ImageInput";
import store from "@/store/store.js";

require("../../../assets/css/neo.css");
export default {
  name: "user-profile",
  props: {
    formData: Object,
  },
  components: {
    ImageInput,
  },
  data: function () {
    return {
      uid: null,
      valid: null,
      lazy: true,
      steps: [
        {
          target: ".v-step-0",
          header: {
            title: this.$t("_edit_profile.Tour_Change_Profile_Picture"),
          },
          content: this.$t("_edit_profile.Tour_Change_Profile_Picture_Content"),
        },
        {
          target: ".v-step-1",
          header: {
            title: this.$t("_edit_profile.Tour_Not_Allowed"),
          },
          content: this.$t("_edit_profile.Tour_Not_Allowed_Content"),
        },
        {
          target: ".v-step-2",
          header: {
            title: this.$t("_edit_profile.Tour_Save"),
          },
          content: this.$t("_edit_profile.Tour_Save_Content"),
        },
      ],
      firstNameRules: [
        (v) => !!v || "First Name is required",
        (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
      ],
      lastNameRules: [
        (v) => !!v || "Last Name is required",
        (v) => (v && v.length <= 50) || "Name must be less than 50 characters",
      ],
      firstName: null,
      lastName: null,
      username: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      validations: {
        emailRules: this.$goc.validate.create().required().email(),
        nameRules: this.$goc.validate.create().required().min(2).max(50),
      },
      email: null,
      avatar: {},
      saved: false,
      card_header_props: {
        header: {
          headLine: this.$t("_edit_profile.Edit_Profile"),
          subTitle: this.$t("_edit_profile.Subtitle"),
          icon: "verified_user",
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false,
        },
      },
    };
  },
  methods: {
    startTour() {
      this.$tours["userProfile"].options.labels.buttonNext =
        this.$t("_tour.Next");
      this.$tours["userProfile"].options.labels.buttonPrevious =
        this.$t("_tour.Previous");
      this.$tours["userProfile"].options.labels.buttonSkip =
        this.$t("_tour.Skip");
      this.$tours["userProfile"].options.labels.buttonStop =
        this.$t("_tour.Finish");
      this.$tours["userProfile"].start();
    },
    getUserDto() {
      let userDto = {};
      userDto.FirstName = this.firstName;
      userDto.LastName = this.lastName;
      userDto.LastName = this.lastName;
      userDto.UserName = this.username;
      userDto.Email = this.email;
      userDto.Id = this.uid;
      return userDto;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.avatar.formData) {
          this.$goc.request.postMultipart(
            this.$enums.API.Images.Single,
            this.avatar.formData,
            (data) => {
              let avatarUrl = data.result.url;
              let userDto = this.getUserDto();
              if (avatarUrl) {
                userDto.Avatar = avatarUrl;
                store.commit("UPDATE_USER_INFO", userDto, { root: true });
              } else {
                userDto.Avatar = require("@/assets/images/portrait/small/avatar.png");
                store.commit("UPDATE_USER_INFO", userDto, { root: true });
              }

              this.updateProfileCall(userDto);
            }
          );
        } else {
          let userDto = this.getUserDto();
          this.updateProfileCall(userDto);
        }
      }
    },
    updateProfileCall(userDto) {
      this.$goc.console.log(userDto);
      this.$goc.request.post(this.$enums.API.Update_Profile, userDto, () => {
        this.$goc.notify.success({
          title: this.$t("_common.Success"),
          message: this.$t("_edit_profile.Profile_is_edited"),
        });
      });
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  computed: {},
  mounted: function () {
    this.username = this.formData.DisplayName;
    this.email = this.formData.Email;
    this.firstName = this.formData.FirstName;
    this.lastName = this.formData.LastName;
    this.uid = this.formData.Uid;
    this.avatar.imageURL = this.$store.state.AppActiveUser.Avatar;
  },
  watch: {},
};
</script>
