<template>
  <div id="add-users">
    <vx-card>
      <v-container>
          <v-row align="center"
                 justify="center">
            <v-flex md12>
              <v-card class="flex-fill">
                <user-profile-form :form-data="this.$store.state.AppActiveUser"/>
              </v-card>
            </v-flex>
          </v-row>
      </v-container>
    </vx-card>
  </div>
</template>
<script>
  import UserProfileForm from "../../../layouts/components/user/UserProfileForm";

  export default {
    components: {UserProfileForm},
    data: function () {
      return {}
    },
    comments: {
      UserProfileForm
    },
    props: {},
    computed: {}
  }
</script>
